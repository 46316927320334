import * as React from "react";
import { graphql } from "gatsby";
import { connect } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
// import { Button } from '@material-ui/core';
import Layout from "../components/layout";
import clsx from 'clsx';
import useCommonStyles from '../assets/style/commonStyle';
import { isMobile } from 'react-device-detect';
// import * as loggingActions from '../redux/actions/loggingActions';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
  RadialLinearScale,
  Filler
} from 'chart.js';
import { Bar, Pie, Line, Doughnut, Radar } from 'react-chartjs-2';
// import Helper from "../lib/helper";
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
  RadialLinearScale,
  Filler
);


const DashBoardPage = (props) => {
  const commonClasses = useCommonStyles();
  const theme = useTheme();
  const locale = props.ui.get('lang');
  // const sysConfig = Helper.getStateAsJSObj(props.system, 'setting.data.configs', []);
  // const logConfig = sysConfig.find(item => item.category === 'system' && item.identifier === 'system.logging');
  // const logEnabled = Helper.carefullyGetValue(logConfig, 'value.log_enable'.split('.'), false);
  // const consoleLogEnabled = Helper.carefullyGetValue(logConfig, 'value.console_log_enable'.split('.'), false);
  
  const chartContainerStyle = {
    minWidth: isMobile ? '90vw' : '30vw',
    height: isMobile ? '27vh' : '30vh',
    // borderColor: theme.palette.type === 'dark' ? '#fff5' : '#0005',
    // borderStyle: 'dashed',
    // borderWidth: 1
  };
  const pieChartContainerStyle = {
    minWidth: isMobile ? '90vw' : '25vw',
    // paddingLeft: isMobile ? '20vw' : '5vw',
    // paddingRight: isMobile ? '20vw' : '5vw',
    minHeight: isMobile ? '27vh' : '40vh',
    // borderColor: theme.palette.type === 'dark' ? '#fff5' : '#0005',
    // borderStyle: 'dashed',
    // borderWidth: 1
  };
  const salesChart = {
    data: {
      labels: locale === 'en-US' ?
        ['January', 'February', 'March', 'April', 'May', 'June', 'July'] :
        ['一月', '二月', '三月', '四月', '五月', '六月', '七月'],
      datasets: [
        {
          label: locale === 'en-US' ? 'Retail' : '实体店',
          data: Array(7).fill(0).map(() => Math.ceil(Math.random() * 1000)),
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: locale === 'en-US' ? 'Online' : '网上销售',
          data: Array(7).fill(0).map(() => Math.ceil(Math.random() * 1000)),
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
          labels: {
            color: theme.palette.type === 'dark' ? '#fff' : '#111'
          }
        },
        title: {
          display: true,
          text: locale === 'en-US' ? 'Sales Statistics' : '销售统计',
          font: {
            size: 18
          },
          color: theme.palette.type === 'dark' ? '#0f0' : '#000'
        },
      },
      scales: {
        yAxes: {
          grid: {
            borderColor: theme.palette.type === 'dark' ? '#fff' : '#111',
            tickColor: theme.palette.type === 'dark' ? '#fff' : '#111',
            color: theme.palette.type === 'dark' ? '#0f01' : '#0001'
          },
          ticks: {
            color: theme.palette.type === 'dark' ? '#0f0' : '#000'
          }
        },
        xAxes: {
          grid: {
            borderColor: theme.palette.type === 'dark' ? '#fff' : '#111',
            color: theme.palette.type === 'dark' ? '#0f01' : '#0001'
          },
          ticks: {
            color: theme.palette.type === 'dark' ? '#fff' : '#111'
          }
        }
      }
    }
  };
  const memberChart = {
    data: {
      labels: locale === 'en-US' ?
        ['Active', 'Withdraw', 'Expired'] :
        ['活跃', '退出', '过期'],
      datasets: [
        {
          data: [85, 5, 10],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)'
          ],
          borderWidth: 1
        }
      ],
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'right',
          labels: {
            color: theme.palette.type === 'dark' ? '#fff' : '#111'
          }
        },
        title: {
          display: true,
          text: locale === 'en-US' ? 'Member Statistics' : '会员统计',
          font: {
            size: 18
          },
          color: theme.palette.type === 'dark' ? '#0f0' : '#000'
        },
      },
      // scales: {
      //   yAxes: {
      //     grid: {
      //       borderColor: theme.palette.type === 'dark' ? '#fff' : '#111',
      //       tickColor: theme.palette.type === 'dark' ? '#fff' : '#111',
      //       color: theme.palette.type === 'dark' ? '#0f01' : '#0001'
      //     },
      //     ticks: {
      //       color: theme.palette.type === 'dark' ? '#0f0' : '#000'
      //     }
      //   },
      //   xAxes: {
      //     grid: {
      //       borderColor: theme.palette.type === 'dark' ? '#fff' : '#111',
      //       color: theme.palette.type === 'dark' ? '#0f01' : '#0001'
      //     },
      //     ticks: {
      //       color: theme.palette.type === 'dark' ? '#fff' : '#111'
      //     }
      //   }
      // }
    }
  };
  const onlineSaleChart = {
    data: {
      labels: locale === 'en-US' ?
        ['January', 'February', 'March', 'April', 'May', 'June', 'July'] :
        ['一月', '二月', '三月', '四月', '五月', '六月', '七月'],
      datasets: [
        {
          label: locale === 'en-US' ? 'Online User' : '在线用户',
          data: Array(7).fill(0).map(() => Math.ceil(Math.random() * 1000)),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          yAxisID: 'y'
        },
        {
          label: locale === 'en-US' ? 'Online Order' : '在线订单',
          data: Array(7).fill(0).map(() => Math.ceil(Math.random() * 200)),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
          yAxisID: 'y',
        },
      ],
    },
    options: {
      responsive: true,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      stacked: false,
      plugins: {
        legend: {
          position: 'top',
          labels: {
            color: theme.palette.type === 'dark' ? '#fff' : '#111'
          }
        },
        title: {
          display: true,
          text: locale === 'en-US' ? 'Online Sales Statistics' : '在线销售统计',
          font: {
            size: 18
          },
          color: theme.palette.type === 'dark' ? '#0f0' : '#000'
        },
      },
      scales: {
        y: {
          grid: {
            borderColor: theme.palette.type === 'dark' ? '#fff' : '#111',
            tickColor: theme.palette.type === 'dark' ? '#fff' : '#111',
            color: theme.palette.type === 'dark' ? '#0f01' : '#0001'
          },
          ticks: {
            color: theme.palette.type === 'dark' ? '#0f0' : '#000'
          }
        },
        xAxes: {
          grid: {
            borderColor: theme.palette.type === 'dark' ? '#fff' : '#111',
            color: theme.palette.type === 'dark' ? '#0f01' : '#0001'
          },
          ticks: {
            color: theme.palette.type === 'dark' ? '#fff' : '#111'
          }
        }
      }
    }
  };
  const staffChart = {
    data: {
      labels: locale === 'en-US' ?
        ['Present', 'On Leave', 'Absent'] :
        ['出席', '休假', '缺席'],
      datasets: [
        {
          data: [95, 4, 1],
          backgroundColor: [
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(255, 99, 132, 0.2)',
          ],
          borderColor: [
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(255, 99, 132, 1)'
          ],
          borderWidth: 1
        }
      ],
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'right',
          labels: {
            color: theme.palette.type === 'dark' ? '#fff' : '#111'
          }
        },
        title: {
          display: true,
          text: locale === 'en-US' ? 'Staff Strength Statistics' : '员工出席率',
          font: {
            size: 18
          },
          color: theme.palette.type === 'dark' ? '#0f0' : '#000'
        },
      }
    }
  };
  const serviceChart = {
    data: {
      labels: locale === 'en-US' ?
        ['Price', 'Quality', 'Delivery', 'Value', 'Support'] :
        ['价格', '质地', '运输', '性价比', '客服'],
      datasets: [
        {
          label: locale === 'en-US' ? '% of Feedback' : '用户反馈比率',
          data: Array(5).fill(0).map(() => {
            let value = 0;
            do {
              value = Math.ceil(Math.random() * 100);
            } while (value < 50);
            
            return value;
          }),
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1
        }
      ],
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
          labels: {
            color: theme.palette.type === 'dark' ? '#fff' : '#111'
          }
        },
        title: {
          display: true,
          text: locale === 'en-US' ? 'Quality of Service' : '服务素质',
          font: {
            size: 18
          },
          color: theme.palette.type === 'dark' ? '#0f0' : '#000'
        },
      },
      scale: {
        min: 0,
        max: 100,
      },
      scales: {
        r: {
          angleLines: {
            color: theme.palette.type === 'dark' ? '#0f0' : '#000'
          },
          grid: {
            color: theme.palette.type === 'dark' ? '#0f02' : '#0002'
          },
          pointLabels: {
            color: theme.palette.type === 'dark' ? '#fff' : '#111'
          },
          
          // ticks: {
          //   color: theme.palette.type === 'dark' ? '#fff' : '#111',
          // }
        }
      }
    }
  };
  const refundChart = {
    data: {
      labels: locale === 'en-US' ?
        ['January', 'February', 'March', 'April', 'May', 'June', 'July'] :
        ['一月', '二月', '三月', '四月', '五月', '六月', '七月'],
      datasets: [
        {
          label: locale === 'en-US' ? 'Refunded' : '已退款',
          data: Array(7).fill(0).map(() => Math.ceil(Math.random() * 100)),
          
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: locale === 'en-US' ? 'Accepted' : '已接受',
          data: Array(7).fill(0).map(() => Math.ceil(Math.random() * 1000)),
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        }
      ],
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
          labels: {
            color: theme.palette.type === 'dark' ? '#fff' : '#111'
          }
        },
        title: {
          display: true,
          text: locale === 'en-US' ? 'Accepted vs Refunded Order' : '接受与退款订单',
          font: {
            size: 18
          },
          color: theme.palette.type === 'dark' ? '#0f0' : '#000'
        },
      },
      scales: {
        yAxes: {
          stacked: true,
          grid: {
            borderColor: theme.palette.type === 'dark' ? '#fff' : '#111',
            tickColor: theme.palette.type === 'dark' ? '#fff' : '#111',
            color: theme.palette.type === 'dark' ? '#0f01' : '#0001'
          },
          ticks: {
            color: theme.palette.type === 'dark' ? '#0f0' : '#000'
          }
        },
        xAxes: {
          stacked: true,
          grid: {
            borderColor: theme.palette.type === 'dark' ? '#fff' : '#111',
            color: theme.palette.type === 'dark' ? '#0f01' : '#0001'
          },
          ticks: {
            color: theme.palette.type === 'dark' ? '#fff' : '#111'
          }
        }
      }
    }
  };
  
  return(
    <Layout 
      // childrenStyle={{padding: 5, paddingTop: 20}}
    >
      <div className={clsx(commonClasses.flexFullColumn)}>
        <div className={commonClasses.flexFullRow}
          style={{flexWrap: 'wrap', rowGap: 20, justifyContent: 'space-around'}}
        >
          <div style={chartContainerStyle}>
            <Bar options={salesChart.options} data={salesChart.data} />
          </div>
          <div style={chartContainerStyle}>
            <Bar options={refundChart.options} data={refundChart.data} />
          </div>
          <div style={chartContainerStyle}>
            <Line options={onlineSaleChart.options} data={onlineSaleChart.data} />
          </div>
          <div style={pieChartContainerStyle}>
            <Doughnut options={staffChart.options} data={staffChart.data} />
          </div>
          <div style={pieChartContainerStyle}>
            <Radar options={serviceChart.options} data={serviceChart.data} />
          </div>
          <div style={pieChartContainerStyle}>
            <Pie options={memberChart.options} data={memberChart.data} />
          </div>
          
        </div>
        
      </div>
    </Layout>
  );
}


export default connect((state) => {
  return {
    session: state.session,
    system: state.system,
    ui: state.ui
  };
})(DashBoardPage);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
